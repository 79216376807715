import { render, staticRenderFns } from "./Santiago-Do-Cacém.vue?vue&type=template&id=483bb63d&scoped=true&"
import script from "./Santiago-Do-Cacém.vue?vue&type=script&lang=js&"
export * from "./Santiago-Do-Cacém.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483bb63d",
  null
  
)

export default component.exports